import React from "react";
import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import SideImg from "../assets/img/about/about.jpg";
export default function AboutSection() {
    return (
        <Container id="about">
            <Grid container mt={3} alignItems="center">
                <Grid item xs={12} md={5}>
                    <img
                        src={SideImg}
                        alt="img"
                        style={{ width: "100%", height: "auto" }}
                    />
                </Grid>
                <Grid p={1} item xs={12} md={7} mb={2}>
                    <Typography variant="body1" sx={{ fontSize: "44px" }}>
                        ABOUT THE
                    </Typography>
                    <Typography
                        mt={1}
                        sx={{ fontSize: "34px", fontWeight: 700 }}
                        className="text-uppercase"
                    >
                        Developer
                    </Typography>
                    <Typography mt={1} sx={{ fontSize: "16px" }}>
                        Indigo Developers is a trustworthy and reliable
                        developer with extensive experience in the acquisition,
                        development, and marketing of real estate projects.
                        Several residential, commercial real estate, and
                        high-rise mixed-use building projects have been
                        completed and delivered. Our projects truly reflect
                        world-class construction quality, the best design, and
                        top-notch amenities. We believe that providing
                        exceptional services and developing relationships leads
                        to success. Each of our projects reflects our passion
                        and creativity, earning us community respect, customer
                        loyalty, and industry recognition.
                    </Typography>
                    <Typography mt={2} sx={{ fontSize: "16px" }}>
                        Indigo Developers is currently building an iconic
                        14-story residential luxury apartment complex that
                        combines contemporary architectural heritage with modern
                        design. Indigo Signature, an ultra-modern architectural
                        phenomenon built on 5 kanals, combines the comforts of
                        home with the desire for luxury for both residents and
                        visitors. The beautifully entrance and spacious
                        reception presents a grand welcome for you and your
                        guests. High speed elevators and wide passage allow
                        facilitate your movement to any part of the Project.
                        Gorgeous and expansded green spaces within the premises
                        provide serene atmosphere for resident, while the
                        apartments are naturally ventilated and offer stunning
                        city views.
                    </Typography>
                    <Typography mt={2} sx={{ fontSize: "16px" }}>
                        Lahore Development Authority (LDA) has approved Indigo
                        Signature as an earthquake-resistant structure. The
                        structure has a 14-story master plan that is divided
                        into two basements and eleven upper floors to create a
                        secure and peaceful environment for its residents.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}
