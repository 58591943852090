import { Box, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import React from "react";
import { GoPrimitiveDot } from "react-icons/go";

const locationAndTimeData = [
    {
        time: "1-minute drive from",
        location: "Kalma Chowk",
    },
    {
        time: "1-minute drive from",
        location: "Main Boulevard",
    },
    {
        time: "1-minute drive from",
        location: "Centre Point",
    },
    {
        time: "5-minute drive from",
        location: "M.M. Alam Road",
    },
    {
        time: "5-minute drive from",
        location: "Liberty Market",
    },
    {
        time: "6-minute drive from",
        location: "Gaddafi Stadium",
    },
];

const LocationAndTime = ({ time, location }) => {
    return (
        <Box>
            <Typography
                variant="body1"
                className="fw-bold"
                sx={{ fontSize: "12px" }}
                pl={1}
            >
                {time} {location}
            </Typography>
        </Box>
    );
};

export default function MobLocation() {
    return (
        <div>
            <Box>
                <Container>
                    <Grid container>
                        <Grid
                            items
                            xs={12}
                            md={12}
                            lg={5}
                            // className="location-content-section"
                        >
                            <Typography
                                my={1}
                                variant="body1"
                                sx={{ fontSize: "12px" }}
                            >
                                Prestigious location in 15 Sher Shah Block
                                Garden Town, the heart of Lahore. Home to the
                                best educational, commercial, healthcare and
                                recreational facilities.
                            </Typography>

                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Box
                                        sx={{ display: "flex" }}
                                        flexDirection="row"
                                    >
                                        <GoPrimitiveDot color="#40276C" />
                                        <Typography
                                            pl={1}
                                            variant="body1"
                                            sx={{
                                                fontSize: "12px",
                                            }}
                                            className="fw-bold"
                                        >
                                            Opposite Model Town’s Entrance,
                                            instantaneously adjacent to
                                            Ferozepur Road
                                        </Typography>
                                    </Box>
                                </Grid>
                                {locationAndTimeData.map((data, index) => (
                                    <Grid item xs={12} md={6} key={index}>
                                        <Box
                                            mt={1}
                                            sx={{ display: "flex" }}
                                            flexDirection="row"
                                        >
                                            <GoPrimitiveDot color="#40276C" />
                                            <LocationAndTime
                                                time={data.time}
                                                location={data.location}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
}
