import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Logo from "../assets/img/logo.png";

const drawerWidth = 240;
const navItems = [
    {
        hideOnMobile: true,
        id: "#about",
        name: "About the Developer",
    },
    {
        hideOnMobile: false,
        mobID: "#project-highlights",
        id: "#project",
        name: "Project Highlights",
    },
    {
        hideOnMobile: false,
        mobID: "#location-highlights",
        id: "#location",
        name: "Location Highlights",
    },
    {
        hideOnMobile: true,
        id: "#gallery",
        name: "Gallery",
    },
    {
        hideOnMobile: false,
        mobID: "#why-invest",
        id: "#why",
        name: "Why Invest?",
    },
    {
        hideOnMobile: false,
        mobID: "#facilities-amenities",
        id: "#facilites",
        name: "Facilites & Amenities",
    },
    {
        hideOnMobile: false,
        mobID: "#price-plan",
        id: "#price",
        name: "Price Plan",
    },
    {
        hideOnMobile: false,
        mobID: "#construction-updates",
        id: "#construction",
        name: "Construction Updates",
    },
];

function Navbar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const handleGoToLocation = (location) => {
        const element = document.getElementById(location);
        if (element) {
            console.log("element", element);
            element.scrollIntoView();
        }
        // window.location = "/" + location;
    };
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Indigo
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <>
                        {!item.hideOnMobile && (
                            <ListItem key={item.name}>
                                <ListItemButton sx={{ textAlign: "center" }}>
                                    <a
                                        style={{
                                            color: "#404041",
                                            textDecoration: "none",
                                        }}
                                        href={item.mobID}
                                        onClick={() =>
                                            handleGoToLocation(item.mobID)
                                        }
                                    >
                                        {item.name}
                                    </a>
                                </ListItemButton>
                            </ListItem>
                        )}
                    </>
                ))}
            </List>
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box>
            <CssBaseline />
            <AppBar
                component="nav"
                sx={{ background: "transparent", position: "static" }}
            >
                <Box className="d-lg-none d-flex justify-content-between align-items-center p-2">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ ml: 2 }}
                        className="d-lg-none"
                    >
                        <MenuIcon className="text-black" />
                    </IconButton>
                    <img
                        src={Logo}
                        style={{
                            width: "110px",
                            height: "60px",
                            margin: "0 20px",
                        }}
                    />
                </Box>
                <Toolbar className="d-lg-flex d-none align-items-center">
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {
                                xs: "none",
                                sm: "flex",
                                justifyContent: "space-between",
                            },
                        }}
                        className="d-lg-flex d-none align-items-center"
                    >
                        <img
                            src={Logo}
                            style={{
                                width: "110px",
                                height: "60px",
                                margin: "0 20px",
                            }}
                        />
                        {navItems.map((item) => (
                            <a
                                key={item}
                                href={item.id}
                                className="underline-border quick-sand-font"
                                style={{
                                    paddingTop: "30px",
                                    paddingBottom: "30px",
                                    borderRadius: "0px",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    color: "#404041",
                                    textDecoration: "none",
                                }}
                            >
                                {item.name}
                            </a>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    // sx={{
                    //     display: { xs: "block", sm: "none" },
                    //     "& .MuiDrawer-paper": {
                    //         boxSizing: "border-box",
                    //         width: drawerWidth,
                    //     },
                    // }}
                    className="d-lg-none d-block"
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

Navbar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Navbar;
