import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

import PricePlanImg from "../../assets/img/limited-offer/limited-offer.jpg";

export default function MobPrice() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const data = [
    {
      panel: "panel1",
      title: "1 Bed Apartment",
      priceStartingFrom: "1,5000,000",
      installmentPlan: "250,000",
      src: "https://www.youtube.com/embed/bBK_qqZgU98",
    },
    {
      panel: "panel2",
      title: "2 Bed Apartment",
      priceStartingFrom: "2,3750,000",
      installmentPlan: "395,833",
      src: "https://www.youtube.com/embed/Hh8169keM-c",
    },
    {
      panel: "panel3",
      title: "3 Bed Apartment",
      priceStartingFrom: "4,3750,000",
      installmentPlan: "729,167",
      src: "https://www.youtube.com/embed/UqS4qLKS2yo",
    },
    {
      panel: "panel4",
      title: "Studio Apartments",
      priceStartingFrom: "11,250,000",
      installmentPlan: "187,500",
      src: "https://www.youtube.com/embed/63NHXTR2Vj0",
    },
    {
      panel: "panel5",
      title: "Penthouses",
      priceStartingFrom: "84,000,000",
      installmentPlan: "1400,000",
      src: "https://www.youtube.com/embed/bJKM-2lYkvo",
    },
  ];

  const AccordionComponent = ({
    panel,
    title,
    priceStartingFrom,
    installmentPlan,
    src,
  }) => {
    return (
      <Accordion expanded={expanded === panel} onChange={handleChange(panel)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="text-center">Price Starting From</Typography>
          <Typography className="text-center fw-bold" sx={{ fontSize: "22px" }}>
            {/* priceStartingFrom */}
            <span style={{ fontSize: "15px" }}>PKR</span> {priceStartingFrom}
          </Typography>
          <Typography className="text-center">
            installment starting from
          </Typography>
          <Typography className="text-center fw-bold" sx={{ fontSize: "22px" }}>
            <span style={{ fontSize: "15px" }}>PKR</span> {installmentPlan}
          </Typography>
          <Box sx={{ backgroundColor: "#f8f8f8" }}>
            <Typography className="text-center">
              <span className="fw-bold">36 months</span> installment plan
            </Typography>
          </Box>
          <Typography className="text-center">
            <span className="fw-bold " style={{ fontSize: "22px" }}>
              30%
            </span>{" "}
            Down Payment
          </Typography>

          <Box>
            <iframe
              width="100%"
              height="230"
              src={src}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div id="price-plan">
      <img
        src={PricePlanImg}
        width={180}
        style={{
          display: "block",
          margin: "10px auto",
        }}
      />

      {data.map((item, index) => (
        <AccordionComponent
          key={index}
          panel={item.panel}
          priceStartingFrom={item.priceStartingFrom}
          src={item.src}
          title={item.title}
          installmentPlan={item.installmentPlan}
        />
      ))}

      <Box className="d-flex mt-2 justify-content-center">
        <a
          href="/payment-plan.pdf"
          target="_blank"
          className="icon-button text-decoration-none text-uppercase"
        >
          download payment plan
        </a>
      </Box>
    </div>
  );
}
