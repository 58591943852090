import React from "react";
import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Carousel from "react-material-ui-carousel";
import Img1 from "../assets/img/gallery/gallery.jpg";
import next from "../assets/img/mainslider/arrow_next.jpg";
import prev from "../assets/img/mainslider/arrow_prev.jpg";
import side1 from "../assets/img/mainslider/side1.jpg";
import side2 from "../assets/img/mainslider/side2.jpg";

import S1 from "../assets/img/slider-2/1.png";
import S2 from "../assets/img/slider-2/2.png";

export default function Gallery() {
    var items = [
        {
            img: Img1,
        },
        {
            img: S1,
        },
        {
            img: S2,
        },
    ];
    function Item(props) {
        return (
            <img
                src={props.item.img}
                style={{ objectFit: "cover", width: "100%" }}
                alt="Slider"
            />
        );
    }
    return (
        <Grid container alignItems="top" className="bgslide" id="gallery">
            <Container>
                <Typography
                    pt={5}
                    className="mb-0"
                    sx={{
                        fontSize: "34px",
                        fontWeight: 700,
                    }}
                >
                    Gallery
                </Typography>
                <Grid
                    item
                    xs={12}
                    md={12}
                    justifyContent={"center"}
                    alignSelf={"center"}
                    className="gallery"
                >
                    <Carousel
                        fullHeightHover={false} // We want the nav buttons wrapper to only be as big as the button element is
                        navButtonsProps={{
                            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                background: "none",
                                opacity: 1,
                            },
                        }}
                        navButtonsWrapperProps={{
                            // Move the buttons to the bottom. Unsetting top here to override default style.
                            style: {
                                display: "none",
                            },
                        }}
                        // NextIcon={
                        //   <div
                        //     className="next-slidee"
                        //     style={{
                        //       backgroundImage: `url(${next})`,
                        //       backgroundRepeat: "none",
                        //       width: "120px",
                        //       height: "120px",
                        //       backgroundPosition: "120px 0",
                        //       right: "0px",
                        //       transition: "all 0.5s ease",
                        //     }}
                        //   />
                        // } // Change the "inside" of the next button to "next"
                        // PrevIcon={
                        //   <div
                        //     className="prev-slidee"
                        //     style={{
                        //       backgroundImage: `url(${prev})`,
                        //       backgroundRepeat: "none",
                        //       width: "120px",
                        //       height: "120px",
                        //       backgroundPosition: "0px 0",
                        //       left: "0px",
                        //       transition: "all 0.5s ease",
                        //     }}
                        //   />
                        // }
                        cycleNavigation={true}
                        autoPlay={true}
                        swipe={true}
                        animation="slide"
                        navButtonsAlwaysVisible={false}
                        indicators={true}
                    >
                        {items.map((item, i) => (
                            <Item key={i} item={item} />
                        ))}
                    </Carousel>
                </Grid>
            </Container>
        </Grid>
    );
}
