import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EmailIcon from "@mui/icons-material/Email";
import emailjs from "@emailjs/browser";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CachedIcon from "@mui/icons-material/Cached";
import { toast } from "react-toastify";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Email({ forMobile = false, span = false }) {
  const [state, setState] = React.useState({
    right: false,
  });

  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [securityCode, setSecurityCode] = React.useState("");
  const [confirmSecurityCode, setConfirmSecurityCode] = React.useState("");
  const form = React.useRef();

  const showSuccessMessage = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const showErrorMessage = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function generateRandomString() {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charactersLength = characters.length;
    for (var i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setSecurityCode(result);
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (securityCode === confirmSecurityCode) {
      if (name && email && phone && message) {
        e.preventDefault();
        emailjs
          .sendForm(
            "service_x9kvein",
            "template_5yvf53e",
            e.target,
            "pQ8dkKPFasnUJt-s1"
          )
          .then((res) => {
            if (res.status === 200) {
              alert("Submit Successfully"); 
              setName("");
              setPhone("");
              setEmail("");
              setMessage("");
            }
          })
          .catch((err) => {
            if (err?.status === 0) {
              alert("Email not send");
            }
          });

        e.target.reset();
      } else {
        alert("Something is Missing");
      }
    } else {
      showErrorMessage("Security code didn't match");
    }
  };

  React.useEffect(() => {
    generateRandomString();
  }, []);

  const list = (anchor) => (
    <Box
      sx={{
        width: {
          lg: "40vw",
          md: "40vw",
          sm: "100vw",
          xs: "100vw",
        },
        overflow: "hidden",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
    >
      <div className="col-12 bg-white px-0">
        <div
          className="row border-bottom p-2 d-flex align-items-center "
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <div className="col-1">
            <ArrowBackIosIcon
              onClick={toggleDrawer(anchor, false)}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="col-11">
            <div className="d-flex justify-content-center align-items-center">
              <p className="mb-0 fw-700">Email Enquiry Form</p>
            </div>
          </div>
        </div>
        <form ref={form} className="contact-form mt-2" onSubmit={sendEmail}>
          <div className="input-box">
            <label className="text-uppercase" htmlFor="">
              Name *
            </label>
            <input
              type="text"
              value={name}
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-box">
            <label className="text-uppercase" htmlFor="">
              Email *
            </label>
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-box">
            <label className="text-uppercase" htmlFor="">
              Phone *
            </label>
            <PhoneInput
              placeholder="3xx xxxxxxx"
              value={phone}
              onChange={setPhone}
              country="pk"
              name="phone"
            />
          </div>
          <input
            value={phone}
            style={{ display: "none" }}
            type="text"
            name="phone"
          />
          <div className="input-box">
            <label className="text-uppercase" htmlFor="">
              message *
            </label>
            <textarea
              name="message"
              value={message}
              placeholder="Write your message here"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="input-box">
            <label className="text-uppercase" htmlFor="">
              security code *
            </label>
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-6">
                <input
                  className="bg-white py-1 px-1"
                  type="text"
                  onChange={(e) => setConfirmSecurityCode(e.target.value)}
                />
              </div>
              <div className="col-6 d-flex px-0">
                <h5 className="c-primary mb-0">{securityCode}</h5>
                <span className="mx-3">
                  <CachedIcon
                    onClick={() => generateRandomString()}
                    className="c-primary"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 mb-3">
              <button className="default-button text-white" type="submit">
                <div className="d-flex align-items-center justify-content-center">
                  {/* <LocalPostOfficeIcon className='f-13' /> */}
                  <span className="d-inline-block mx-1 text-white">
                    Send Email
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="f-12">
            By submitting this form, I agree to{" "}
            <span className="text-primary pointer">
              <u>Terms of Use</u>
            </span>
            .
          </div>
        </form>
      </div>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {forMobile ? (
            <button
              onClick={toggleDrawer(anchor, true)}
              className="text-uppercase y-btn mobile-btn mb-0"
            >
              book your unit now!
            </button>
          ) : (
            <>
              {!span ? (
                <Button
                  className="icon-button"
                  onClick={toggleDrawer(anchor, true)}
                  style={{ borderRadius: "50px" }}
                >
                  Book Your Unit Now
                </Button>
              ) : (
                <span
                  className="white"
                  onClick={toggleDrawer(anchor, true)}
                  style={{
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  Book Your Unit Now!
                </span>
              )}
            </>
          )}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
