import { Block } from "@mui/icons-material";
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function VideoPopup({ children }) {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <span color="danger" onClick={toggle}>
                {children}
            </span>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            width: "50%",
                            margin: "0 auto",
                        }}
                    >
                        <iframe
                            width="100%"
                            style={{
                                height: "80vh",
                            }}
                            src="https://www.youtube.com/embed/gwWph9amKRA"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default VideoPopup;
