import React from "react";
import { Box, Grid } from "@mui/material";
import Container from "@mui/material/Container";
import Carousel from "react-material-ui-carousel";
import Img1 from "../assets/img/mainslider/1.jpg";
import next from "../assets/img/mainslider/arrow_next.jpg";
import prev from "../assets/img/mainslider/arrow_prev.jpg";
import side1 from "../assets/img/mainslider/side1.jpg";
import side2 from "../assets/img/mainslider/side2.jpg";

import S1 from "../assets/img/slider-1/1.png";
import S2 from "../assets/img/slider-1/2.png";
import VideoPopup from "./VideoPopup";

export default function MainSlider() {
    var items = [
        {
            img: Img1,
        },
        {
            img: S1,
        },
        {
            img: S2,
        },
    ];
    function Item(props) {
        return (
            <img
                src={props.item.img}
                style={{ objectFit: "cover", width: "100%" }}
                alt="Slider"
            />
        );
    }
    return (
        <section>
            <Grid container alignItems="top">
                <Grid item xs={12} md={12} lg={4} pt={20} className="bgmain">
                    <Container>
                        <Box className="px-4">
                            <h1 className="mainh1">
                                The Height of{" "}
                                <span className="yellow">Luxury</span>
                            </h1>
                            <Grid
                                container
                                rowGap={3}
                                mt={3}
                                sx={{
                                    marginBottom: {
                                        lg: "80px",
                                        md: "20px",
                                        sm: "20px",
                                        xs: "20px",
                                    },
                                }}
                                alignItems="top"
                            >
                                <Grid item xs={12} md={6}>
                                    <p className="imghead text-lg-start text-center">
                                        Studio, 1, 2 & 3
                                    </p>
                                    <p className="imghead text-lg-start text-center">
                                        Bedroom Apartments
                                    </p>
                                    <img
                                        className="mt-4 d-lg-inline d-block mx-auto"
                                        src={side1}
                                        width={150}
                                        alt=""
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p className="imghead text-lg-start text-center">
                                        Single-storey &
                                    </p>
                                    <p className="imghead text-lg-start text-center">
                                        Duplex Penthouses
                                    </p>
                                    <img
                                        className="mt-4 d-lg-inline d-block mx-auto"
                                        src={side2}
                                        width={150}
                                        alt=""
                                    />
                                </Grid>
                            </Grid>
                            <Box
                                className="d-flex"
                                sx={{
                                    flexDirection: "row",
                                    alignItems: {
                                        lg: "flex-start",
                                        md: "center",
                                        sm: "center",
                                        xs: "center",
                                    },
                                }}
                            >
                                <Box>
                                    <VideoPopup>
                                        <button
                                            className="btn btn-lg y-btn me-1"
                                            style={{
                                                height: "100%",
                                                lineHeight: "100%",
                                                padding: "10px 20px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            WATCH TVC
                                        </button>
                                    </VideoPopup>
                                </Box>
                                <Box
                                    sx={{
                                        marginTop: {
                                            lg: "0",
                                            md: "20px",
                                            sm: "20px",
                                            xs: "20px",
                                        },
                                        marginBottom: {
                                            lg: "0",
                                            md: "20px",
                                            sm: "20px",
                                            xs: "20px",
                                        },
                                    }}
                                >
                                    <a
                                        href="/Catalog-Indigo-Signature.pdf"
                                        target="_blank"
                                        className="text-decoration-none btn-lg w-btn text-uppercase d-inline-block"
                                        style={{
                                            padding: "11px 20px",
                                            lineHeight: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        download catalogue
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={8}
                    sx={{
                        display: {
                            lg: "block",
                            md: "none",
                            sm: "none",
                            xs: "none",
                        },
                    }}
                >
                    <Carousel
                        fullHeightHover={false} // We want the nav buttons wrapper to only be as big as the button element is
                        navButtonsProps={{
                            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                background: "none",
                                opacity: 1,
                            },
                        }}
                        navButtonsWrapperProps={{
                              // Move the buttons to the bottom. Unsetting top here to override default style.
                            style: {
                                bottom: "50px",
                                top: "unset",
                            },
                        }}
                        NextIcon={
                            <Box
                                className="next-slidee"
                                style={{
                                    backgroundImage: `url(${next})`,
                                    backgroundRepeat: "none",
                                    width: "120px",
                                    height: "120px",
                                    backgroundPosition: "120px 0",
                                    right: "0px",
                                    transition: "all 0.5s ease",
                                }}
                            />
                        } // Change the "inside" of the next button to "next"
                        PrevIcon={
                            <Box
                                className="prev-slidee"
                                style={{
                                    backgroundImage: `url(${prev})`,
                                    backgroundRepeat: "none",
                                    width: "120px",
                                    height: "120px",
                                    backgroundPosition: "0px 0",
                                    left: "0px",
                                    transition: "all 0.5s ease",
                                }}
                            />
                        }
                        cycleNavigation={true}
                        autoPlay={true}
                        swipe={true}
                        animation="slide"
                        navButtonsAlwaysVisible={true}
                        indicators={false}
                    >
                        {items.map((item, i) => (
                            <Item key={i} item={item} />
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </section>
    );
}
