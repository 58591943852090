import "./App.css";
import Navbar from "./components/Navbar";
import AboutSection from "./components/AboutSection";
import MainSlider from "./components/MainSlider";
import Highlights from "./components/Highlights";
import Location from "./components/Location";
import Gallery from "./components/Gallery";
import MySlider from "./components/MySlider";
import "swiper/swiper.min.css";
import Investment from "./components/Investment";
import Footer from "./components/Footer";
import VerticalSlider from "./components/VerticalSlider";
import MobileVersion from "./components/MobileVersion";
import { useEffect } from "react";
import StickyBottomSection from "./components/mobileVersion/StickyBottomSection";
import { ToastContainer } from "react-toastify";
import Email from "./components/Email";

function App() {
    useEffect(() => {
        const element = document.querySelectorAll(".slick-slide");
        // console.log('asdhkajsdhkjashdkhaskjdhaskjdhkjash;',element)

        element.forEach((ele) => {
            ele.style.width = "100%";
        });
    }, []);

    return (
        <>
            <Navbar />

            <div className="display-on-lg">
                <MainSlider />
                <div className="sticky_bar_outter">
                    <div className="sticky_bar_wrapper sticky_phone_bar position-absolute text-uppercase">
                        <span className="stikcy_phone_icon position-absolute display-block"></span>
                        PAK: +92 330 302 2222 | 111-808-000
                    </div>
                    <a className="book_now sticky_bar_wrapper sticky_form_bar position-absolute text-uppercase text-decoration-none">
                        <span className="stikcy_form_icon position-absolute display-block"></span>
                        <Email span />
                    </a>
                </div>
                <AboutSection />
                <Highlights />
                <Location />
                <Gallery />
                <Investment />
                <MySlider />
                <VerticalSlider />
                <Footer />
            </div>
            <div className="display-on-md position-relative">
                <MobileVersion />
                <Footer />
                <StickyBottomSection />
            </div>

            <ToastContainer />
        </>
    );
}

export default App;
